import styled from "styled-components";
import { IoRadioButtonOff, IoRadioButtonOn } from "react-icons/io5";

const RadioButton = ({ text, id: propId, ...props }) => {
  const id = propId ?? text;

  return (
    <Container>
      <StyledInput id={id} type="radio" {...props} />
      <StyledLabel htmlFor={id}>
        <IoRadioButtonOff size={20} className="unchecked" />
        <IoRadioButtonOn size={20} className="checked" />
        {text}
      </StyledLabel>
    </Container>
  );
};

const Container = styled.div`
  padding: 1.5rem 0.5rem;
  border-bottom: solid #f1f1f2 1px;
`;

const StyledLabel = styled.label`
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #5f676c;
  font-size: 0.8rem;

  svg {
    margin-right: 0.5rem;
    color: #bfc5ca;
  }

  svg.checked {
    color: #1aa1e6;
  }
`;

const StyledInput = styled.input`
  display: none;

  & + label svg.checked {
    display: none;
  }

  &:checked + label svg.checked {
    display: initial;
  }

  & + label svg.unchecked {
    display: initial;
  }

  &:checked + label svg.unchecked {
    display: none;
  }
`;

export default RadioButton;
