import styled from "styled-components";
import Typography from "../../components/Typography";
import { Input, Button } from "../../components/form/";
import { FiArrowRight } from "react-icons/fi";
import { useEffect, useMemo, useState } from "react";
import { useGlobalContext } from "../../contexts/Global";
import { login } from "../../services/api";
import Loading from "../../components/Loading";
import { toast } from "react-toastify";

const Login = () => {
  const { data, changeStep, changeData } = useGlobalContext();
  const [cpf_cnpj, setCpf_cnpj] = useState("");
  const [password, setPassword] = useState("");

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setCpf_cnpj(data.cpf_cnpj || "");
  }, [data]);

  useEffect(() => {
    window.dataLayer.push({
      event: "tracking",
      step: "step-1-1",
    });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    changeData({ cpf_cnpj });
    login(cpf_cnpj.replace(/\D/g, ""), password)
      .then(() => changeStep("alreadyclientplan"))
      .catch((err) =>
        toast.error(
          err?.response?.data?.message?.message ||
            "Algo deu errado, por favor tente novamente"
        )
      )
      .finally(() => setLoading(false));
  };

  const errors = useMemo(() => {
    return {
      cpf_cnpj:
        !cpf_cnpj ||
        /(^\d{3}\.\d{3}\.\d{3}-\d{2}$)|(^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$)/.test(
          cpf_cnpj
        )
          ? ""
          : "Insira um documento válido",
    };
  }, [cpf_cnpj]);

  const disableButton = useMemo(
    () => !cpf_cnpj || errors.cpf_cnpj || !password,
    [cpf_cnpj, password, errors]
  );

  const cpfCnpjMask = useMemo(
    () =>
      cpf_cnpj.replace(/\D/g, "").length < 12
        ? "999.999.999-999"
        : "99.999.999/9999-99",
    [cpf_cnpj]
  );

  return (
    <Container>
      <Loading isloading={loading} text="Um momento..." />
      <Typography title>Acesse sua conta</Typography>
      <Typography>
        Digite seu CPF ou CNPJ e senha para acessar sua conta.
      </Typography>
      <Form onSubmit={handleSubmit}>
        <div>
          <Input
            label="CPF/CNPJ"
            value={cpf_cnpj}
            mask={cpfCnpjMask}
            onChange={(e) => setCpf_cnpj(e.target.value)}
            placeholder="Ex: XX. XXX. XXX/0001-XX."
            error={errors.cpf_cnpj}
            required
          />
          <Input
            type="password"
            label="Senha"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Insira sua senha aqui"
            required
          />
        </div>
        <Button disabled={disableButton} icon={FiArrowRight}>
          Próximo
        </Button>
      </Form>
    </Container>
  );
};

const Container = styled.div`
  padding: 1rem 1rem 1.5rem;
  display: flex;
  flex-flow: column nowrap;
  min-height: 100%;
`;

const Form = styled.form`
  flex: 1;
  padding-top: 24px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
`;

export default Login;
