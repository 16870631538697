import styled from "styled-components";

const Typography = ({ title, children }) => {
  return title ? <Title>{children}</Title> : <Paragraph>{children}</Paragraph>;
};

const Title = styled.p`
  color: #292e32;
  font-weight: 600;
  font-size: 1.5rem;
  margin: 0 0 24px 0;
`;

const Paragraph = styled.p`
  color: #798289;
  margin: 0;
`;

export default Typography;
