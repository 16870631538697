import styled from "styled-components";
import Typography from "../../components/Typography";
import { Button } from "../../components/form/";
import { useGlobalContext } from "../../contexts/Global";
import { FaCrown } from "react-icons/fa";
import { TbInfoCircle } from "react-icons/tb";
import { useEffect, useState } from "react";
import { pay } from "../../services/api";
import Loading from "../../components/Loading";
import { toast } from "react-toastify";

const TicketBill = () => {
  const { changeStep, changeTicket } = useGlobalContext();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.dataLayer.push({
      event: "tracking",
      step: "step-2-6",
    });
  }, []);

  const onClick = (e) => {
    e.preventDefault();
    setLoading(true);
    pay("invoice")
      .then((res) => {
        changeTicket(res.data);
        changeStep("ticketbillsuccess");
      })
      .catch((err) =>
        toast.error(
          err?.response?.data?.message?.message ||
            "Algo deu errado, por favor tente novamente"
        )
      )
      .finally(() => setLoading(false));
  };

  return (
    <Container>
      <Loading isloading={loading} text="Estamos gerando seu boleto..." />
      <Content>
        <Typography title>Boleto</Typography>
        <Typography>
          Agora, gere o boleto do plano para fazer o pagamento.
        </Typography>

        <PlanInfo>
          <Typography>Resumo do Pedido</Typography>
          <hr />
          <div>
            <FaCrown size={24} color="#fdc40f" />
            <Typography>
              Plano PRO <strong>R$ 389,00/mês</strong>
            </Typography>
          </div>
        </PlanInfo>

        <InfoBox>
          <TbInfoCircle size={24} color="#1aa1e6" />
          <Typography>
            <strong>Se liga!</strong> A confirmação de pagamento por boleto pode
            levar até <strong>3 dias úteis</strong>. Entraremos em contato após
            confirmação.
          </Typography>
        </InfoBox>
      </Content>

      <Button onClick={onClick}>Gerar boleto para pagar</Button>
    </Container>
  );
};

const Container = styled.div`
  padding: 1rem 1rem 1.5rem;
  display: flex;
  flex-flow: column nowrap;
  min-height: 100%;
`;

const PlanInfo = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid #dde1e5;
  padding: 1rem;
  margin-top: 1rem;

  & > p {
    font-weight: bold;
    color: #292e32;
  }

  hr {
    border: solid 1px #dde1e5;
    border-radius: 1px;
    width: 100%;
    margin: 1rem 0;
  }

  div {
    display: flex;
    flex-direction: row;
    font-size: 0.8rem;

    strong {
      color: #292e32;
      font-weight: 500;
    }

    svg {
      margin-right: 0.5rem;
    }
  }
`;

const InfoBox = styled.div`
  background-color: #e1f5ff;
  display: flex;
  border-radius: 5px;
  padding: 1rem;
  margin-top: 1rem;
  flex-direction: row nowrap;

  p {
    font-size: 0.8rem;
    width: calc(calc(100% - 1rem) - 24px);
    margin-left: 1rem;
  }
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-flow: column nowrap;

  & > p {
    margin-bottom: 8px;
  }
`;

export default TicketBill;
