import styled from "styled-components";
import { useEffect } from "react";
import Typography from "../../components/Typography";
import { Button } from "../../components/form/";
import { FaCrown } from "react-icons/fa";
import { BsCheckLg } from "react-icons/bs";
import planImage from "../../assets/plan_image.jpg";
import { useGlobalContext } from "../../contexts/Global";

const NewClientPlan = () => {
  const { changeStep } = useGlobalContext();

  useEffect(() => {
    window.dataLayer.push({
      event: "tracking",
      step: "step-2-4",
    });
  }, []);

  const onClick = (e) => {
    e.preventDefault();
    changeStep("paymentchoice");
  };

  return (
    <Container>
      <Image src={planImage} alt="Food" />
      <Data>
        <Plan>
          <Typography title>
            <FaCrown size={28} /> Plano PRO
          </Typography>
          <Typography>
            R$ <span>389</span>/mês
          </Typography>
        </Plan>
        <Facilities>
          <Typography title>Benefícios do plano:</Typography>
          <div>
            <BsCheckLg size={22} />
            <Typography>
              Cardápio digital integrado ao Robô de WhatsApp.
            </Typography>
          </div>

          <div>
            <BsCheckLg size={22} />
            <Typography>
              Centraliza os pedidos do iFood e Rappi em um só lugar
            </Typography>
          </div>

          <div>
            <BsCheckLg size={22} />
            <Typography>Integração com Pix e pagamento online.</Typography>
          </div>

          <div>
            <BsCheckLg size={22} />
            <Typography>
              Relatório diário automatizado no seu WhatsApp.
            </Typography>
          </div>

          <div>
            <BsCheckLg size={22} />
            <Typography>
              E <span>muuuito</span> mais
            </Typography>
          </div>
        </Facilities>
      </Data>
      <Button onClick={onClick}>Selecionar plano</Button>
    </Container>
  );
};

const Container = styled.div`
  padding: 1rem 1rem 1.5rem;
  display: flex;
  flex-flow: column nowrap;
  min-height: 100%;
  justify-content: flex-end;
  position: relative;
`;
const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;

const Data = styled.div`
  padding: 1rem;
  display: flex;
  flex-flow: column nowrap;
  background-color: #fff;
  border-radius: 5px;
  z-index: 1;
`;

const Plan = styled.div`
  padding: 1rem 0;
  border-bottom: 1px solid #f3f3f3;

  p {
    text-align: center;

    span {
      color: #1aa1e6;
      font-size: 48px;
      font-weight: 700;
    }
  }

  p:first-child {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    color: #fdc40f;
    margin-right: 0.5rem;
  }
`;

const Facilities = styled.div`
  padding-top: 16px;

  & > p {
    text-align: center;
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }

  svg {
    color: #1aa1e6;
  }

  div {
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 0.7rem;

    p {
      font-size: 14px;
      width: calc(calc(100% - 1rem) - 22px);
      flex: 1;
      margin-left: 1rem;
    }

    span {
      font-weight: bold;
      color: #1aa1e6;
    }
  }
`;

export default NewClientPlan;
