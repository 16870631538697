import styled from "styled-components";
import Typography from "../../components/Typography";
import { FaRegCreditCard } from "react-icons/fa6";
import { BsChevronRight, BsUpcScan } from "react-icons/bs";
import { useGlobalContext } from "../../contexts/Global";
import { useEffect } from "react";

const PaymentChoice = () => {
  const { changeStep } = useGlobalContext();

  useEffect(() => {
    window.dataLayer.push({
      event: "tracking",
      step: "step-2-5",
    });
  }, []);

  return (
    <Container>
      <Typography title>Como quer pagar?</Typography>
      <Typography>Escolha um método de pagamento</Typography>
      <Option onClick={() => changeStep("creditcard")}>
        <FaRegCreditCard />
        <Typography>Cartão de Crédito</Typography>
        <BsChevronRight />
      </Option>
      <Option onClick={() => changeStep("ticketbill")}>
        <BsUpcScan />
        <Typography>Boleto</Typography>
        <BsChevronRight />
      </Option>
    </Container>
  );
};

const Container = styled.div`
  padding: 1rem 1rem 1.5rem;
  display: flex;
  flex-flow: column nowrap;
  min-height: 100%;

  & > p {
    margin-bottom: 16px;
  }

  & > p:first-child {
    margin-bottom: 8px;
  }
`;

const Option = styled.div`
  display: flex;
  padding: 24px 8px;
  align-items: center;
  flex-direction: row;
  border-bottom: solid #f1f1f2 1px;
  cursor: pointer;
  transition: background-color 0.1s linear;

  p {
    flex: 1;
    font-size: 0.8rem;
    padding: 0 0.5rem;
  }

  &:active {
    background-color: #f1f1f2;
  }
`;

export default PaymentChoice;
