import GlobalStyle from "./assets/GlobalStyle";
import StepManager from "./components/StepManager";
import { GlobalProvider } from "./contexts/Global";
import TagManager from "react-gtm-module";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const tagManagerArgs = {
  gtmId: "GTM-WMZNTFT",
};
TagManager.initialize(tagManagerArgs);

function App() {
  return (
    <GlobalProvider>
      <GlobalStyle />
      <StepManager />
      <ToastContainer
        position="top-right"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </GlobalProvider>
  );
}

export default App;
