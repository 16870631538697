import styled from "styled-components";

const Select = ({ label, id, options, ...props }) => {
  return (
    <Container>
      <StyledLabel>
        {label}
        {props.required && <span>*</span>}
      </StyledLabel>
      <StyledSelect id={id} {...props}>
        {options?.map(({ key, value, disabled, selected }) => (
          <option
            key={key}
            value={value}
            disabled={disabled}
            selected={selected}
          >
            {key}
          </option>
        ))}
      </StyledSelect>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledLabel = styled.label`
  font-weight: 500;
  font-size: 14px;
  padding-bottom: 5px;
  color: #5f676c;

  span {
    color: #1aa1e6;
  }
`;

const StyledSelect = styled.select`
  border: solid #bfc5ca 1px;
  border-radius: 5px;
  flex: 1;
  padding: 18px 1rem;
  -webkit-appearance: menulist-button;
  -moz-appearance: none;
  color: ${({ value }) => (!!value ? "#5f676c" : "#909ba5")};
  background-color: transparent;

  &:focus-visible {
    outline: #1aa1e6 auto 1px;
  }
`;

export default Select;
