import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import asyncStorage from "../services/asyncStorage";
import { useTrackingCode } from "react-hubspot-tracking-code-hook";

const GlobalContext = createContext({});

export const useGlobalContext = () => useContext(GlobalContext);

export const GlobalConsumer = GlobalContext.Consumer;

export const GlobalProvider = (props) => {
  const { setIdentity } = useTrackingCode();

  const [step, setStep] = useState("yoogaclient");
  const [data, setData] = useState({});
  const [ticket, setTicket] = useState({});
  const [token, setToken] = useState(null);

  const changeData = useCallback(
    (fields) => {
      const updatedData = { ...data, ...fields };
      updatedData.email && setIdentity(updatedData.email, updatedData);

      setData(updatedData);
      asyncStorage.setItem("data", JSON.stringify(updatedData));
    },
    [data, setIdentity]
  );

  const changeTicket = (value) => {
    setTicket(value);
    asyncStorage.setItem("ticket", JSON.stringify(value));
  };

  const changeStep = (value) => {
    if (value === undefined) return;
    setStep(value);
    asyncStorage.setItem("step", value);
  };

  const changeToken = (value) => {
    setToken(value);
    asyncStorage.setItem("token", value);
  };

  const contextData = useMemo(
    () => ({
      step,
      data,
      token,
      ticket,
      changeStep,
      changeData,
      changeToken,
      changeTicket,
    }),
    [step, data, token, changeData, ticket]
  );

  useEffect(() => {
    setData(JSON.parse(asyncStorage.getItem("data")) ?? {});
    setTicket(JSON.parse(asyncStorage.getItem("ticket")) ?? {});
    setStep(asyncStorage.getItem("step") ?? "yoogaclient");
    setToken(asyncStorage.getItem("token"));
  }, []);

  return <GlobalContext.Provider value={contextData} {...props} />;
};
