import { useState } from "react";
import styled from "styled-components";
import InputMask from "react-input-mask";

import { PiEye, PiEyeClosed } from "react-icons/pi";

const Input = ({ label, error, type, id: propId, value, ...props }) => {
  const [passwordShown, setPasswordShown] = useState(false);
  const id = propId ?? label;

  const getType = () =>
    type === "password" && passwordShown === false ? "password" : "text";

  return (
    <Container>
      <StyledLabel htmlFor={id}>
        {label}
        {props.required && <span>*</span>}
      </StyledLabel>
      <Wrapper>
        <StyledInput
          value={value}
          id={id}
          maskChar=""
          type={getType()}
          $haserror={error}
          $type={type}
          {...props}
        />
        {type === "password" &&
          !!value &&
          (passwordShown ? (
            <PiEye size="24" onClick={() => setPasswordShown(false)} />
          ) : (
            <PiEyeClosed size="24" onClick={() => setPasswordShown(true)} />
          ))}
      </Wrapper>
      <StyledError>{error}</StyledError>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  svg {
    margin-right: 0.5rem;
    margin-left: calc(-0.5rem - 24px);
    color: #5f676c;
    cursor: pointer;
  }
`;

const StyledLabel = styled.label`
  font-weight: 500;
  font-size: 14px;
  padding-bottom: 5px;
  color: #5f676c;

  span {
    color: #1aa1e6;
  }
`;

const StyledInput = styled(InputMask)`
  width: 100%;
  height: 56px;
  color: #798289;
  font-size: 14px;
  border: solid ${({ $haserror }) => (!$haserror ? "#bfc5ca" : "#ff5951")} 1px;
  border-radius: 5px;
  flex: 1;
  margin: 0;
  padding-left: 16px;
  padding-right: ${({ $type }) =>
    $type === "password" ? "calc(1rem + 50px)" : "16px"};

  &:focus-visible {
    outline: #1aa1e6 auto 1px;
  }

  &::placeholder {
    color: #a0abb5;
    opacity: 1; /* Firefox */
    font-weight: 400;
    font-size: 14px;
  }

  &::-ms-input-placeholder {
    /* Edge 12 -18 */
    color: #a0abb5;
  }
`;

const StyledError = styled.span`
  height: 1rem;
  color: #ff5951;
  font-size: 0.8rem;
  vertical-align: top;
  margin-bottom: 0.5rem;
`;

export default Input;
