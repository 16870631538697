import axios from "axios";
import { v4 as uuidv4, validate } from "uuid";
import asyncStorage from "./asyncStorage";

let sessionId = asyncStorage.getItem("session-id");

if (!validate(sessionId)) {
  sessionId = uuidv4();
  asyncStorage.setItem("session-id", sessionId);
}

const instance = axios.create({
  baseURL: "https://gateway.yooga.com.br/customer/v2/",
  headers: {
    "session-id-bind": sessionId,
  },
});

export const sendData = (step, payload) =>
  instance.post("/v2/api/yooga/acquisition/self/delivery", {
    step,
    payload,
  });

export const pay = (payment_method, card) =>
  instance.post("v2/api/yooga/acquisition/self-delivery/pay", {
    payment_method,
    card,
  });

export const login = (login_api, senha_api) =>
  instance.post("v2/api/yooga/acquisition/node-api/auth", {
    login_api,
    senha_api,
  });

export const upsell = () =>
  instance.post("v2/api/yooga/acquisition/node-api/pipe");

export default instance;
