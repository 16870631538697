import styled from "styled-components";
import { useGlobalContext } from "../contexts/Global";
import LayoutFrame from "./LayoutFrame";
import Header from "./Header";

import STEPS_SCHEME from "../stepsScheme";

const StepManager = () => {
  const { step, changeStep } = useGlobalContext();

  const { Component, previousStep, completition } = STEPS_SCHEME[step];

  return (
    <LayoutFrame>
      <Header
        percentage={completition}
        hideBackButton={!previousStep}
        backButtonClick={() => changeStep(previousStep)}
      />
      <Content>
        <Component />
      </Content>
    </LayoutFrame>
  );
};

const Content = styled.main`
  flex: 1;
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

export default StepManager;
