import AlreadyClientPlan from "./steps/AlreadyClient/AlreadyClientPlan";
import Login from "./steps/AlreadyClient/Login";
import AlreadyClientSuccess from "./steps/AlreadyClient/AlreadyClientSuccess";
import ClientData from "./steps/NewClient/ClientData";
import YoogaClient from "./steps/YoogaClient";
import ClientAddress from "./steps/NewClient/ClientAddress";
import ClientPassword from "./steps/NewClient/ClientPassword";
import NewClientPlan from "./steps/NewClient/NewClientPlan";
import PaymentChoice from "./steps/NewClient/PaymentChoice";
import CreditCard from "./steps/NewClient/CreditCard";
import CreditCardSuccess from "./steps/NewClient/CreditCardSuccess";
import TicketBill from "./steps/NewClient/TicketBill";
import TicketBillSuccess from "./steps/NewClient/TicketBillSuccess";

const scheme = {
  yoogaclient: {
    Component: YoogaClient,
    completition: 10,
  },
  clientdata: {
    Component: ClientData,
    completition: 23,
    previousStep: "yoogaclient",
  },
  login: {
    Component: Login,
    completition: 40,
    previousStep: "yoogaclient",
  },
  alreadyclientplan: {
    Component: AlreadyClientPlan,
    completition: 70,
    previousStep: "login",
  },
  alreadyclientsuccess: {
    Component: AlreadyClientSuccess,
    completition: 100,
  },
  clientaddress: {
    Component: ClientAddress,
    completition: 36,
    previousStep: "clientdata",
  },
  clientpassword: {
    Component: ClientPassword,
    completition: 49,
    previousStep: "clientaddress",
  },
  newclientplan: {
    Component: NewClientPlan,
    completition: 62,
  },
  paymentchoice: {
    Component: PaymentChoice,
    completition: 75,
    previousStep: "newclientplan",
  },
  creditcard: {
    Component: CreditCard,
    completition: 88,
    previousStep: "paymentchoice",
  },
  ticketbill: {
    Component: TicketBill,
    completition: 88,
    previousStep: "paymentchoice",
  },
  creditcardsuccess: {
    Component: CreditCardSuccess,
    completition: 100,
  },
  ticketbillsuccess: {
    Component: TicketBillSuccess,
    completition: 100,
  },
};

export default scheme;
