import styled from "styled-components";

const Button = ({ children, icon: Icon, ...props }) => {
  return (
    <StyledButton {...props}>
      <p>{children}</p>
      {!!Icon && <Icon size={18} />}
    </StyledButton>
  );
};

const StyledButton = styled.button`
  padding: 0 1rem;
  height: 56px;
  background-color: ${({ disabled }) => (disabled ? "#f1f2f2" : "#1aa1e6")};
  border: none;
  border-radius: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};

  &,
  * {
    color: ${({ disabled }) => (disabled ? "#a0abb5" : "#ffffff")};
    font-weight: 600;
  }

  svg {
    margin-left: 0.5rem;
  }

  p {
    margin: 0;
  }
`;

export default Button;
