import styled, { keyframes } from "styled-components";
import Typography from "./Typography";

const Loading = ({ isloading, text }) => {
  return (
    <Container $isLoading={isloading}>
      <LoadingRing>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </LoadingRing>
      <Typography>{text}</Typography>
    </Container>
  );
};

const ringAnimation = keyframes`
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
`;

const Container = styled.div`
  z-index: 999;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: ${({ $isLoading }) => ($isLoading ? "flex" : "none")};
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    text-align: center;
    max-width: 60%;
  }
`;

const LoadingRing = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  margin-bottom: 1rem;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #1aa1e6;
    border-radius: 50%;
    animation: ${ringAnimation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #1aa1e6 transparent transparent transparent;
  }

  div:nth-child(1) {
    animation-delay: -0.45s;
  }
  div:nth-child(2) {
    animation-delay: -0.3s;
  }

  div:nth-child(3) {
    animation-delay: -0.15s;
  }
`;

export default Loading;
