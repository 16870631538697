import styled from "styled-components";

const LayoutFrame = ({ children }) => {
  return (
    <Background>
      <Container>{children}</Container>
    </Background>
  );
};

const Background = styled.div`
  width: 100vw;
  min-height: 100svh;
  display: flex;
  justify-content: center;
  background-color: #ffffff;

  @media only screen and (min-width: 768px) {
    align-items: center;
    background-color: #e5e5e5;
    min-height: 100vh;
  }
`;

const Container = styled.div`
  width: 100vw;
  height: 100svh;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;

  @media only screen and (min-width: 768px) {
    width: 41vh;
    min-width: 315px;
    height: 90vh;
    min-height: 680px;
    border-radius: 15px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
`;

export default LayoutFrame;
