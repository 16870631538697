import styled from "styled-components";
import Typography from "../components/Typography";
import { RadioButton, Button } from "../components/form/";
import { FiArrowRight } from "react-icons/fi";
import { useEffect, useMemo, useState } from "react";
import { useGlobalContext } from "../contexts/Global";

const YoogaClient = () => {
  const { data, changeStep, changeData } = useGlobalContext();
  const [isClient, setIsClient] = useState();

  useEffect(() => {
    window.dataLayer.push({
      event: "tracking",
      step: "step-0",
    });
  }, []);

  useEffect(() => {
    setIsClient(data.isClient);
  }, [data]);

  const handleSubmit = (e) => {
    e.preventDefault();
    changeData({ isClient });
    changeStep(isClient === 1 ? "login" : "clientdata");
  };

  const disableButton = useMemo(() => !isClient, [isClient]);

  return (
    <Container>
      <Typography title>Você é cliente Yooga?</Typography>
      <Form onSubmit={handleSubmit}>
        <div>
          <RadioButton
            name="client"
            text="Sim, ja sou cliente"
            value={1}
            onChange={() => setIsClient(1)}
            checked={isClient === 1}
          />
          <RadioButton
            name="client"
            text="Não, ainda não sou"
            value={2}
            onChange={() => setIsClient(2)}
            checked={isClient === 2}
          />
        </div>
        <Button disabled={disableButton} icon={FiArrowRight}>
          Próximo
        </Button>
      </Form>
    </Container>
  );
};

const Container = styled.div`
  padding: 1rem 1rem 1.5rem;
  display: flex;
  flex-flow: column nowrap;
  min-height: 100%;

  & > p {
    margin-bottom: 0;
  }
`;

const Form = styled.form`
  flex: 1;
  padding-top: 1rem;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
`;

export default YoogaClient;
