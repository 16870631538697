import styled from "styled-components";
import Typography from "../../components/Typography";
import { Button } from "../../components/form/";
import { FaCrown } from "react-icons/fa";
import { BsCheckLg } from "react-icons/bs";
import planImage from "../../assets/plan_image.jpg";
import { useGlobalContext } from "../../contexts/Global";
import { useEffect, useState } from "react";
import { upsell } from "../../services/api";
import Loading from "../../components/Loading";

const AlreadyClientPlan = () => {
  const { changeStep } = useGlobalContext();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.dataLayer.push({
      event: "tracking",
      step: "step-1-2",
    });
  }, []);

  const onClick = (e) => {
    e.preventDefault();

    setLoading(true);
    upsell()
      .then(() => changeStep("alreadyclientsuccess"))
      .catch((err) =>
        alert(
          err?.response?.data?.message?.message ||
            "Algo deu errado, por favor tente novamente"
        )
      )
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Loading isloading={loading} text="Um momento..." />
      <Container>
        <Image src={planImage} alt="Food" />
        <Data>
          <Plan>
            <Typography title>
              <FaCrown size={28} /> Plano PRO
            </Typography>
            <Typography>
              R$ <span>389</span>/mês
            </Typography>
          </Plan>
          <Facilities>
            <Typography title>Benefícios do plano:</Typography>
            <div>
              <BsCheckLg size={22} />
              <Typography>
                Cardápio digital integrado ao Robô de WhatsApp.
              </Typography>
            </div>

            <div>
              <BsCheckLg size={22} />
              <Typography>
                Centraliza os pedidos do iFood e Rappi em um só lugar
              </Typography>
            </div>

            <div>
              <BsCheckLg size={22} />
              <Typography>Integração com Pix e pagamento online.</Typography>
            </div>

            <div>
              <BsCheckLg size={22} />
              <Typography>
                Relatório diário automatizado no seu WhatsApp.
              </Typography>
            </div>
          </Facilities>
        </Data>
        <Button onClick={onClick}>Atualizar plano</Button>
      </Container>
    </>
  );
};

const Container = styled.div`
  padding: 1rem 1rem 1.5rem;
  display: flex;
  flex-flow: column nowrap;
  min-height: 100%;
  justify-content: flex-end;
  position: relative;
`;
const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;

const Data = styled.div`
  padding: 1rem;
  display: flex;
  flex-flow: column nowrap;
  background-color: #fff;
  border-radius: 5px;
  z-index: 1;
`;

const Plan = styled.div`
  padding: 1rem 0;
  border-bottom: 1px solid #f3f3f3;

  p {
    text-align: center;

    span {
      color: #1aa1e6;
      font-size: 48px;
      font-weight: 700;
    }
  }

  p:first-child {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    color: #fdc40f;
    margin-right: 0.5rem;
  }
`;

const Facilities = styled.div`
  margin-top: 16px;

  & > p {
    text-align: center;
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }

  svg {
    color: #1aa1e6;
  }

  div {
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 0.7rem;

    p {
      font-size: 14px;
      width: calc(calc(100% - 1rem) - 22px);
      flex: 1;
      margin-left: 1rem;
      color: #5f676c;
    }
  }
`;

export default AlreadyClientPlan;
