const asyncStorage = {
  async setItem(key, value) {
    return localStorage.setItem(`@yooga:${key}`, value);
  },
  getItem(key) {
    return localStorage.getItem(`@yooga:${key}`);
  },
};

export default asyncStorage;
