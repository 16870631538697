import styled from "styled-components";
import { BsSquare, BsCheckSquareFill } from "react-icons/bs";

const Checkbox = ({ text, id: propId, ...props }) => {
  const id = propId ?? text;

  return (
    <>
      <StyledInput id={id} type="checkbox" {...props} />
      <StyledLabel htmlFor={id}>
        <BsSquare size={20} className="unchecked" />
        <BsCheckSquareFill size={20} className="checked" />
        {text}
      </StyledLabel>
    </>
  );
};

const StyledLabel = styled.label`
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #5f676c;
  font-size: 0.8rem;
  margin-bottom: 0.5rem;

  svg {
    margin-right: 0.5rem;
    color: #bfc5ca;
  }

  svg.checked {
    color: #1aa1e6;
  }
`;

const StyledInput = styled.input`
  display: none;

  & + label svg.checked {
    display: none;
  }

  &:checked + label svg.checked {
    display: initial;
  }

  & + label svg.unchecked {
    display: initial;
  }

  &:checked + label svg.unchecked {
    display: none;
  }
`;

export default Checkbox;
