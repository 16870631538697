import styled from "styled-components";
import Typography from "../../components/Typography";
import { Button } from "../../components/form/";
import { FaPix } from "react-icons/fa6";
import { BsUpcScan } from "react-icons/bs";
import { useEffect, useState } from "react";
import { useGlobalContext } from "../../contexts/Global";
import { toast } from "react-toastify";

const TicketBillSuccess = () => {
  const { ticket } = useGlobalContext();
  const [dueDate, setDueDate] = useState("");

  useEffect(() => {
    window.dataLayer.push({
      event: "tracking",
      step: "step-2-7",
    });
  }, []);

  useEffect(() => {
    const ticketDueDate = new Date(ticket.data_vencimento);

    setDueDate(
      `${ticketDueDate.getDate()} ${ticketDueDate
        .toLocaleString("pt-br", {
          month: "short",
        })
        .replace(".", "")
        .toUpperCase()}`
    );
  }, [ticket]);

  const copyPixToClipboard = () =>
    navigator.clipboard
      .writeText(ticket.qr_code_emv)
      .then(() => toast.success("Pix copiado!"));

  const copyTicketToClipboard = () =>
    navigator.clipboard
      .writeText(ticket.linha_digitavel)
      .then(() => toast.success("Código do boleto copiado!"));

  const openTicketBill = () => window.open(ticket.invoice_url, "_blank");

  return (
    <Container>
      <Content>
        <Typography title>
          O boleto de R$ 389,00 foi enviado por e-mail!
        </Typography>
        <Typography>
          Vencimento em <strong>{dueDate}</strong>
        </Typography>

        <Payment>
          <Typography>
            <FaPix />
            Pix copia e cola
          </Typography>
          <Typography>
            Copie a chave e cole na área Pix do seu aplicativo de banco para
            pagar.
          </Typography>
          <Button onClick={copyPixToClipboard}>
            Copiar chave Pix Copia e Cola
          </Button>
        </Payment>

        <hr />

        <Payment>
          <Typography>
            <BsUpcScan />
            Código do boleto
          </Typography>
          <Typography>
            Use o número do código de barras para pagar o boleto.
          </Typography>
          <Button onClick={copyTicketToClipboard}>
            Copiar código do boleto
          </Button>
        </Payment>
      </Content>

      <StyledButton onClick={openTicketBill}>
        Gerar boleto para pagar
      </StyledButton>
    </Container>
  );
};

const Container = styled.div`
  padding: 1rem 1rem 1.5rem;
  display: flex;
  flex-flow: column nowrap;
  min-height: 100%;
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-flow: column nowrap;

  hr {
    width: 100%;
    border: 1px solid #f1f2f2;
  }

  & > p {
    margin-bottom: 8px;
  }
`;

const Payment = styled.div`
  margin: 1.5rem 0;

  svg {
    margin-right: 1rem;
  }

  & > p:first-child {
    font-weight: 600;
    color: #292e32;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }

  & > p:nth-child(2) {
    font-size: 0.8rem;
    margin-bottom: 1rem;
  }
`;

const StyledButton = styled(Button)`
  background-color: #f1f2f2;
  p {
    color: #1aa1e6;
  }
`;

export default TicketBillSuccess;
