import styled from "styled-components";
import { BsChevronLeft } from "react-icons/bs";

const Header = ({ percentage, hideBackButton, backButtonClick }) => {
  return (
    <Container>
      <BsChevronLeft
        size={20}
        onClick={backButtonClick}
        className={hideBackButton ? "hidden" : ""}
      />
      <ProgressBar $percentage={percentage ?? 10} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 1rem 1rem;

  svg {
    cursor: pointer;
    margin-right: 1rem;
    transition: width 0.5s ease-in-out;
  }

  svg.hidden {
    width: 0;
  }
`;

const ProgressBar = styled.div`
  margin: 0 1rem;
  background-color: #dde1e5;
  height: 4px;
  flex: 1;
  border-radius: 2px;

  &::before {
    content: "";
    display: block;
    height: 100%;
    background-color: #1aa1e6;
    width: ${({ $percentage }) => `${$percentage}%`};
    border-radius: 2px;
  }
`;

export default Header;
