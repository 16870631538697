import styled from "styled-components";
import Typography from "../../components/Typography";
import { Input, Button, Select } from "../../components/form/";
import { FiArrowRight } from "react-icons/fi";
import { useEffect, useMemo, useState } from "react";
import { useGlobalContext } from "../../contexts/Global";
import STATES from "../../assets/states.json";
import { sendData } from "../../services/api";
import { searchZipCode } from "../../services/viacep";
import { useTrackingCode } from "react-hubspot-tracking-code-hook";
import Loading from "../../components/Loading";
import { toast } from "react-toastify";

const ClientAddress = () => {
  const { setTrackPageView } = useTrackingCode();
  const { data, changeStep, changeData } = useGlobalContext();
  const [zip_code, setZip_code] = useState("");
  const [street, setStreet] = useState("");
  const [number, setNumber] = useState("");
  const [complement, setComplement] = useState("");
  const [neighborhood, setNeighborhood] = useState("");
  const [city, setCity] = useState("");
  const [uf, setUf] = useState("");

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setZip_code(data.zip_code || "");
    setStreet(data.street || "");
    setNumber(data.number || "");
    setComplement(data.complement || "");
    setNeighborhood(data.neighborhood || "");
    setCity(data.city || "");
    setUf(data.uf || "");
  }, [data]);

  useEffect(() => {
    window.dataLayer.push({
      event: "tracking",
      step: "step-2-2",
    });
  }, []);

  useEffect(() => {
    if (/^[0-9]{5}-[0-9]{3}$/.test(zip_code)) {
      searchZipCode(zip_code).then((res) => {
        if (res.data) {
          setStreet(res.data.logradouro || "");
          setNeighborhood(res.data.bairro || "");
          setCity(res.data.localidade || "");
          setUf(res.data.uf || "");
        }
      });
    }
  }, [zip_code]);

  useEffect(() => {
    setTrackPageView();
  }, [setTrackPageView]);

  const handleSubmit = (e) => {
    e.preventDefault();
    changeData({
      zip_code,
      street,
      number,
      complement,
      neighborhood,
      city,
      uf,
    });
    e.preventDefault();
    setLoading(true);
    sendData("second", {
      postal_code: zip_code,
      address: street,
      address_extra: complement,
      district: neighborhood,
      state: uf,
      number,
      city,
    })
      .then(() => changeStep("clientpassword"))
      .catch((err) =>
        toast.error(
          err?.response?.data?.message?.message ||
            "Algo deu errado, por favor tente novamente"
        )
      )
      .finally(() => setLoading(false));
  };

  const errors = useMemo(
    () => ({
      zip_code:
        !zip_code || /^[0-9]{5}-[0-9]{3}$/.test(zip_code)
          ? ""
          : "Insira um CEP válido",
    }),
    [zip_code]
  );

  const disableButton = useMemo(
    () => !zip_code || !street || !number || !neighborhood || !city || !uf,
    [zip_code, street, number, neighborhood, city, uf]
  );

  return (
    <Container>
      <Loading isloading={loading} text="Um momento..." />
      <Typography title>Agora, seu endereço</Typography>
      <Form onSubmit={handleSubmit}>
        <div>
          <StyledInput
            label="CEP"
            mask="99999-999"
            value={zip_code}
            onChange={(e) => setZip_code(e.target.value)}
            placeholder="Ex: 00000-000"
            error={errors.zip_code}
            required
          />
          <StyledInput
            label="Rua/Avenida"
            value={street}
            onChange={(e) => setStreet(e.target.value)}
            placeholder="Ex: Rua Izidro Benezath"
            required
          />
          <InputRow>
            <StyledInput
              label="Número"
              value={number}
              mask="99999"
              onChange={(e) => setNumber(e.target.value)}
              placeholder="Ex: 123"
              required
            />
            <StyledInput
              label="Complemento"
              value={complement}
              onChange={(e) => setComplement(e.target.value)}
              placeholder="Ex: Apto 301"
            />
          </InputRow>
          <StyledInput
            label="Bairro"
            value={neighborhood}
            onChange={(e) => setNeighborhood(e.target.value)}
            placeholder="Ex: Enseada do Suá"
            required
          />
          <StyledInput
            label="Cidade"
            value={city}
            onChange={(e) => setCity(e.target.value)}
            placeholder="Ex: Vitória"
            required
          />
          <StyledSelect
            label="Estado (UF)"
            value={uf}
            onChange={(e) => setUf(e.target.value)}
            options={STATES}
            defaultValue={undefined}
            required
          />
        </div>
        <Button disabled={disableButton} icon={FiArrowRight}>
          Próximo
        </Button>
      </Form>
    </Container>
  );
};

const Container = styled.div`
  padding: 1rem 1rem 1.5rem;
  display: flex;
  flex-flow: column nowrap;
  min-height: 100%;
`;

const Form = styled.form`
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
`;

const StyledInput = styled(Input)`
  margin-bottom: 0.5rem;
`;

const StyledSelect = styled(Select)`
  margin-bottom: 1.5rem;
`;

const InputRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 1rem;
  & > * {
    flex: 1;
    width: 40%;
  }
`;

export default ClientAddress;
